import React, { useEffect, useState, useContext } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PredioComponent from "./generics/predios.js";
import api from "../api";
import { AuthContext } from "../context/GlobalContextProvider";
import { Button, Skeleton } from "antd";
import { navigate } from "gatsby";
import { SafetyCertificateOutlined } from "@ant-design/icons";
import ReactMarkdown from "react-markdown";
import pin from "../images/pin-icon.png";
import calculator from "../images/calculator-icon.png";

function IndexPage() {
  useEffect(() => {
    getData();
  }, []);
  const context = useContext(AuthContext);
  const [data, setData] = useState();
  const [page, setPage] = useState();
  const [carrossel, setCarrossel] = useState([]);

  const dragItem = React.useRef(null);
  const dragOverItem = React.useRef(null);

  const getData = async () => {
    const response = await api.get("/predios?apareceNoInicio=true&_sort=ordem");
    const page = await api.get("/inicio");
    setData(response.data);
    setPage(page.data);
    setCarrossel(page.data.predios);
  };
  const sortBuildings = async (sortedBuildings) => {
    await sortedBuildings.forEach(async (building) => {
      await api({
        method: "PUT",
        url: `/predios/${building.id}`,
        data: {
          ...building,
          estado:
            building.estado === "Vendido" || building.estado === "Pronto"
              ? "Entregue"
              : building.estado === "ObraConcluida"
              ? "Lancamento"
              : building.estado === "EmAndamento"
              ? "EmConstrucao"
              : building.estado,
        },
        headers: {
          Authorization: `Bearer ${context?.token}`,
        },
      });
    });
  };
  const handleSort = () => {
    //duplicate items
    let _fields = [...data];

    //remove and save the dragged item content
    const draggedItemContent = _fields.splice(dragItem.current, 1)[0];

    //switch the position
    _fields.splice(dragOverItem.current, 0, draggedItemContent);

    //reset the position ref
    dragItem.current = null;
    dragOverItem.current = null;

    const fieldWithNewOrders = _fields.map((f, index) => ({
      ...f,
      ordem: index,
    }));

    //update the actual array
    sortBuildings(fieldWithNewOrders);
    setData(fieldWithNewOrders);
  };
  return (
    <Layout aboveFooter carousel={carrossel} loading={!data} padding={false}>
      {context?.user && (
        <Button
          className="mb-10"
          style={{ borderColor: "orange", color: "orange" }}
          danger
          onClick={() => navigate(`/edicao/inicio`)}
        >
          Editar
        </Button>
      )}
      <React.Fragment>
        <SEO
          keywords={[`Bivver`, `Construção`]}
          description="Empreendimentos"
          title="Bivver"
        />
        <center>
          <h1>Nossos empreendimentos</h1>
        </center>
        <br />
        <div
          className="mx-auto md:w-4/5"
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "120px",
          }}
        >
          <PredioComponent
            {...{
              dragItem,
              dragOverItem,
              handleSort,
            }}
            buildings={data}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "8px",
          }}
        >
          <h2
            style={{
              width: "50%",
              textAlign: "center",
              paddingBottom: "12px",
              borderBottomWidth: "1.5px",
              borderBottomColor: "black",
            }}
          >
            Bivver Empreendimentos
          </h2>
        </div>
        <p
          name="a"
          className="text-xl mb-6 opacity-75"
          style={{ textAlign: "center" }}
        >
          Tenha momentos incríveis em um Bivver!
        </p>
        <div
          className="grid grid-cols-1 md:grid-cols-3 px-12 py-3 gap-24"
          style={{ backgroundColor: "#D27A34" }}
        >
          <div
            style={{
              color: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "8px 18px",
              borderRadius: "32px",
            }}
          >
            <SafetyCertificateOutlined style={{ fontSize: "64px" }} />
            <h2
              id="visao"
              name="visao"
              className="mb-3"
              style={{
                color: "white",
              }}
            >
              Conforto e Segurança
            </h2>
            <Skeleton loading={!page}>
              <div>
                <ReactMarkdown
                  renderers={{
                    // eslint-disable-next-line react/display-name
                    paragraph: (props) => (
                      <p
                        name="a"
                        className="text-xl mb-6 opacity-75"
                        style={{ textAlign: "justify" }}
                        {...props}
                      />
                    ),
                  }}
                  source={page?.confortoSeguranca}
                />
              </div>
            </Skeleton>
          </div>
          <div
            style={{
              color: "white",
              display: "flex",
              flexDirection: "column",
              borderRadius: "32px",
              alignItems: "center",
              padding: "8px 18px",
            }}
          >
            <img src={pin} style={{ height: "64px" }} />
            <h2
              id="visao"
              name="visao"
              className="mb-3"
              style={{
                color: "white",
              }}
            >
              Localização estratégica
            </h2>
            <Skeleton loading={!page}>
              <div>
                <ReactMarkdown
                  renderers={{
                    // eslint-disable-next-line react/display-name
                    paragraph: (props) => (
                      <p
                        name="a"
                        className="text-xl mb-6 opacity-75"
                        style={{ textAlign: "justify" }}
                        {...props}
                      />
                    ),
                  }}
                  source={page?.localizacaoEstrategica}
                />
              </div>
            </Skeleton>
          </div>
          <div
            style={{
              color: "white",
              display: "flex",
              flexDirection: "column",
              borderRadius: "32px",
              alignItems: "center",
              justifyContent: "center",
              padding: "8px 18px",
            }}
          >
            <img src={calculator} style={{ height: "64px" }} />
            <h2
              id="valores"
              name="valores"
              className="mb-3"
              style={{
                color: "white",
              }}
            >
              Facilidades para seu investimento
            </h2>
            <Skeleton loading={!page}>
              <div>
                <ReactMarkdown
                  renderers={{
                    // eslint-disable-next-line react/display-name
                    paragraph: (props) => (
                      <p
                        name="a"
                        className="text-xl mb-6 opacity-75"
                        style={{ textAlign: "justify" }}
                        {...props}
                      />
                    ),
                  }}
                  source={page?.facilidadesInvestimento}
                />
              </div>
            </Skeleton>
          </div>
        </div>
        <div className="md:w-3/4 mx-auto mt-16">
          <ReactMarkdown
            renderers={{
              // eslint-disable-next-line react/display-name
              paragraph: (props) => (
                <p name="a" className="text-2xl mb-6 opacity-75" {...props} />
              ),
            }}
            source={page?.footer}
          />
        </div>
      </React.Fragment>
    </Layout>
  );
}

export default IndexPage;
